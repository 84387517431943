import React from 'react';
import { classNames } from 'primereact/utils';

import EzControlledCheckBox from '../';

const EzControlledCheckBoxWithLabel = (props) => {
  const {
    control,
    name,
    rules,
    onChange,
    labelClassName,
    wrapperClass,
    label,
    errorMsg,
    isError,
    disabled
  } = props;

  const renderLabel = () => {
    const lblClass = classNames({ 'p-error': isError }, labelClassName);

    return (
      <label htmlFor={name} className={lblClass}>
        {rules?.required ? `${label}*` : label}
      </label>
    );
  };

  return (
    <div className={wrapperClass}>
      <EzControlledCheckBox
        name={name}
        onChange={onChange}
        control={control}
        rules={rules}
        disabled={disabled}
      />
      {renderLabel()}
      {errorMsg}
    </div>
  );
};

EzControlledCheckBoxWithLabel.defaultProps = {
  labelClassName: '',
  wrapperClass: 'field-checkbox flex-row',
};

export default EzControlledCheckBoxWithLabel;
