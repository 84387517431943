import { useEffect, useMemo } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Col, Container, Row } from "react-bootstrap";
import EzControlledInput from "../../../common/EzForm/EzControlledInput";
import { FORM_FIELDS_NAME } from "./constant";
import { Divider } from "primereact/divider";
import { useForm } from "react-hook-form";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import "./index.css";

const CreateInvoicesComponent = (props) => {
  const {
    isLoading,
    isEdit,
    isView,
    createInvoice,
    editInvoice,
    isCreateInvoiceSuccess,
    isEditInvoiceSuccess,
    isInvoiceDetailSuccess,
    isEditInvoiceError,
    formFieldValueMap,
    shouldBlockNavigation,
    InvoiceId,
    navigateToInvoicesScreen,
  } = props;

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

  useEffect(() => {
    if (isInvoiceDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isInvoiceDetailSuccess]);

  const onSubmit = (data) => {
    isEdit ? editInvoice({ id: data.id, data }) : createInvoice(data);
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <Button
          tooltip={"Back to Invoices list"}
          tooltipOptions={{ position: "left" }}
          label={"Back to Invoices"}
          // icon={"pi pi-arrow-left"}
          className={`p-button `}
          style={{ borderRadius: "10px" }}
          onClick={navigateToInvoicesScreen}
        />
      </div>
    );
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div className="paddingbox">
      <div
        className={classNames("containerboxform", { "p-disabled": isLoading })}
      >
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
            <Container fluid className="card-box">
              {renderDividerRowWithTime("View Invoices")}
              <Row>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.INVOICE_NUMBER}
                    control={control}
                    label={"Invoice number"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME.INVOICE_NUMBER]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.INVOICE_NUMBER
                    )}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.COMPANY}
                    control={control}
                    label={"Company"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.COMPANY]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.COMPANY)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.STATUS}
                    control={control}
                    label={"Status"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.STATUS]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.STATUS)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.AMOUNT_DUE}
                    control={control}
                    label={"Amount Due"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.AMOUNT_DUE]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.AMOUNT_DUE)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.START_DATE}
                    control={control}
                    label={"Start Date"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.START_DATE]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.START_DATE)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.END_DATE}
                    control={control}
                    label={"End Date"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.END_DATE]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.END_DATE)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.DATA_ISSUED}
                    control={control}
                    label={"Issue Date"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.DATA_ISSUED]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME?.DATA_ISSUED
                    )}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.DATE_DUE}
                    control={control}
                    label={"Due Date"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.DATE_DUE]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.DATE_DUE)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
                <Col md={3}>
                  <EzControlledInput
                    name={FORM_FIELDS_NAME?.TOTAL}
                    control={control}
                    label={"Amount"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME?.TOTAL]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME?.TOTAL)}
                    disabled={isView || !isEdit}
                    inputClassName={
                      isView || !isEdit ? "custom-disabled-input" : ""
                    }
                  />
                </Col>
              </Row>
            </Container>
            {isEdit && (
              <div
                className="make-flex mt-2 mr-2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      label="Cancel"
                      type="button"
                      className="p-btn-danger p-button-outlined mt-2"
                      onClick={navigateToInvoicesScreen}
                    />
                  </div>
                  <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const renderDividerRowWithTime = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          {" "}
          <b className="ml-2">{label || ""}</b>
        </div>
        <div>
          {" "}
          <b className="mr-2">
            <span>Last Updated at</span> {new Date().toLocaleString()}{" "}
          </b>
        </div>
      </div>
      <Divider align={align} type={type} />
    </Row>
  );
};

export default CreateInvoicesComponent;
