import { mstimeconversion, mstimeconversionUTC } from "../../../Services/utils";
import {
  INIT_TRANSACTIONS,
  FETCH_TRANSACTIONS_LIST,
  CREATE_TRANSACTIONS_RECORD,
  EDIT_TRANSACTIONS_RECORD,
  DELETE_TRANSACTIONS_RECORD,
  FETCH_TRANSACTIONS_DETAIL,
  TOAST,
} from "../../../constants/action-types/transactions";
import {
  decorateEmptyGridValues,
  setColumnDefaultExtraProps,
} from "../../../utils/table/gridUtils";

const formFieldValueMap = {
  id: "",
  txn_card: "",
  txn_date: "",
  txn_amount: "",
  txn_price_per_unit: "",
  txn_total_cost: "",
  txn_station: "",
  txn_fuel_type: "",
  company: "",
  status: "",
  created_ip: "",
  modified_ip: "",

  txn_attr: {
    "1wk_age": "",
    "2wk_age": "",
    "3wk_age": "",
    auth_id: "",
    balance_forward: "",
    broker_name: "",
    call_destination_city: "",
    call_origin_city: "",
    fed_exs_tax_rate: "",
    gst_amt: "",
    misc: "",
    net_price: "",
    odometer: "",
    product_code: "",
    product_description: "",
    provincial_fuel_tax_rate: "",
    pst_amt: "",
    qty: "",
    record_count: "",
    sales_type: "",
    station_address: "",
    station_number: "",
    station_province: "",
    total_adj: "",
    total_amount: "",
    total_gst_amt: "",
    total_pst_amt: "",
    total_qty: "",
    trailer_record_type: "",
    transaction_number: "",
  },
};

const INITIAL_STATE = {
  transactionsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateTransactionSuccess: false,
  isCreateTransactionError: false,
  isTransactionDetailError: false,
  isTransactionDetailSuccess: false,
  isEditTransactionSuccess: false,
  isEditTransactionError: false,
  isTransactionExportSuccess: false,
  isTransactionExportError: false,
};

const tranformToTransactionGridView = (data) => {
  const extractTransactionDetails = (
    txn_attr,
    txn_price_per_unit
  ) => {
    if (!txn_attr || txn_attr.length === 0) {
      return {
        dtl_stn_addr: null,
        dtl_stn_prv: null,
        hdr_currency: null,
        modifiedTxnAttr: [],
      };
    }
    const firstTxn = txn_attr[0];

    const { dtl_stn_addr = null, dtl_stn_prv = null, header } = firstTxn;
    const hdr_currency = header ? header.hdr_currency : null;

    // Clone the txn_attr array and add txn_fuel_type and txn_price_per_unit to each item in txn_attr
    const modifiedTxnAttr = txn_attr.map((txn) => ({
      ...txn,
      txn_price_per_unit,
      local_tax: 0,
    }));

    return { dtl_stn_addr, dtl_stn_prv, hdr_currency, modifiedTxnAttr };
  };

  const rows = data.map((item) => {
    const {
      txn_id,
      authentication_id,
      txn_date,
      card_number,
      txn_company,
      txn_amount,
      txn_price_per_unit,
      txn_attr,
    } = item;
    const {
      dtl_stn_addr,
      dtl_stn_prv,
      hdr_currency,
      modifiedTxnAttr,
    } = extractTransactionDetails(txn_attr, txn_price_per_unit);

    const transformedValues = {
      id: txn_id,
      authentication_id,
      txn_date,
      card_number,
      txn_company,
      txn_amount,
      dtl_stn_prv,
      hdr_currency,
      dtl_stn_addr,
      sub_transactions: modifiedTxnAttr,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });
  const subcolumns = [
    {
      colLabel: "Id",
      dataKey: "id",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: true,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Auth Code",
      dataKey: "dtl_auth_id",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Fuel Code",
      dataKey: "dtl_prd_cd",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "PPU",
      dataKey: "txn_price_per_unit",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "FET",
      dataKey: "dtl_fet_rt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "GST",
      dataKey: "dtl_gst_amt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "HST",
      dataKey: "dtl_hst_amt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Local Tax",
      dataKey: "local_tax", //for now comming 0
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "PCT",
      dataKey: "pct",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "PFT",
      dataKey: "dtl_pft_rt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "PST",
      dataKey: "dtl_pst_amt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "QST",
      dataKey: "dtl_qst_amt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Billed Amount",
      dataKey: "dtl_amt",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
  ];
  // txn_attr
  const columns = [
    {
      colLabel: "Column Id",
      dataKey: "id",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: true,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Auth Code",
      dataKey: "authentication_id",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
        sortField: "type.code",
      },
    },
    {
      colLabel: "Time Stamp",
      dataKey: "txn_date",
      extraProps: {
        isSortable: true,
        isDateField: true,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
        sortField: "cat.name",
      },
    },
    {
      colLabel: "Card Number",
      dataKey: "card_number",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
    {
      colLabel: "Site Number",
      dataKey: "siteNumber",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
    {
      colLabel: "Province State",
      dataKey: "dtl_stn_prv",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
    {
      colLabel: "Location",
      dataKey: "dtl_stn_addr",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
    {
      colLabel: "Company",
      dataKey: "txn_company",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search...",
      },
    },
    {
      colLabel: "Amount",
      dataKey: "txn_amount",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
    {
      colLabel: "Currency",
      dataKey: "hdr_currency",
      extraProps: {
        isSortable: true,
        isDateField: false,
        shouldFilter: true,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholde: "Search...",
      },
    },
  ];

  return {
    transactionsListView: { columns, rows, subcolumns },
  };
};

export const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_TRANSACTIONS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_TRANSACTIONS_LIST.START:
    case CREATE_TRANSACTIONS_RECORD.START:
    case FETCH_TRANSACTIONS_DETAIL.START:
    case EDIT_TRANSACTIONS_RECORD.START:
      return { ...state, isLoading: true };

    case FETCH_TRANSACTIONS_LIST.SUCCESS: {
      let { metadata, transactions } = action.payload;
      // content = content.map((item) => ({
      //   ...item,
      //   transaction_datetime:mstimeconversionUTC(item?.transaction_datetime),
      //   txn_attr:JSON.parse(item?.txn_attr)
      // }));
      let resetState = {
        transactionsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateTransactionSuccess: false,
        isCreateTransactionError: false,
        isTransactionListSuccess: false,
        iisTransactionListError: false,
        isEditTransactionSuccess: false,
        isEditManageCardError: false,
        isTransactionExportSuccess: false,
        isTransactionExportError: false,
      };

      if (!transactions) {
        return resetState;
      }
      const { page, size, total_count } = metadata;
      const { transactionsListView } = tranformToTransactionGridView(
        transactions
      );

      return {
        ...resetState,
        transactionsListView,
        paginationInfo: {
          pageSize: size,
          total_elements: transactions.length,
          totalPages: total_count,
          pageNumber: page,
        },
      };
    }

    case FETCH_TRANSACTIONS_LIST.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        transactionsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isTransactionDeletionSuccess: false,
        isTransactionDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_TRANSACTIONS_RECORD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isTransactionDeletionSuccess: false,
        isTransactionDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_TRANSACTIONS_RECORD.SUCCESS: {
      return {
        ...state,
        isTransactionDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    // case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
    //   const shouldOpenDeleteConfirmationPopup = action.payload;
    //   return {
    //     ...state,
    //     shouldOpenDeleteConfirmationPopup,
    //     isTransactionDeletionError: false,
    //     isTransactionDeletionSuccess: false,
    //   };
    // }

    // case INIT_CREATE_NEW_TRANSACTIONS_SCREEN: {
    //   return { ...INITIAL_STATE };
    // }

    case FETCH_TRANSACTIONS_DETAIL.SUCCESS: {
      const temp = formFieldValueMap;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            //   if (
            //     key === "cash_refresh_days" ||
            //     key === "weekly_vehicle_quantity_refresh_day" ||
            //     key === "non_highway_fuel_weekly_refresh_day"
            //   ) {
            //     const value = JSON.parse(action.payload[key]);
            //     if (value && typeof value === "object") {
            //       Object?.keys(temp[key])?.forEach((day) => {
            //         temp[key][day] = value?.hasOwnProperty(day)
            //           ? value[day]
            //           : false;
            //       });
            //     }
            //   } else {
            //     temp[key] = action.payload[key];
            //   }
            temp[key] = action.payload[key];
          }
        });
      }
      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isTransactionDetailError: false,
        isTransactionDetailSuccess: true,
      };
    }

    case FETCH_TRANSACTIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isTransactionDetailError: true,
        isTransactionDetailSuccess: false,
      };
    }

    case CREATE_TRANSACTIONS_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateTransactionSuccess: false,
        isCreateTransactionError: true,
      };
    }
    case CREATE_TRANSACTIONS_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateTransactionSuccess: true,
        isCreateTransactionError: false,
      };
    }

    case EDIT_TRANSACTIONS_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditTransactionSuccess: false,
        isEditTransactionError: true,
      };
    }

    case EDIT_TRANSACTIONS_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditTransactionSuccess: true,
        isEditTransactionError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateTransactionSuccess: false,
        isCreateTransactionError: false,
        isEditTransactionError: false,
        isEditTransactionSuccess: false,
        isTransactionDetailError: false,
      };
    }
    default:
      return state;
  }
};