import  { useEffect, useRef, useState } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import invoicesData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import Invoices from "../../components/Invoices";
import { connect } from "react-redux";
import { initializeInvoiceScreen, fetchInvoice, downloadInvoice } from "../../Redux/Actions/invoice";
import DownloadModal from "../../common/DownloadModal";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import { Toast } from 'primereact/toast';

const InvoicesScreen = (props) => {

  const {
    fetchInvoice,
    downloadInvoice,
    history,
    initializeInvoiceScreen,
    showPageLevelLoader,
    invoiceListView,
    paginationInfo,
    isLoading,
    screenPermissions,
    isDownloadSuccess,
    isDownloadError
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileType, setFileType] = useState("PDF");
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = useRef(null);
  
  const closeModal = () => setIsModalVisible(false);
  
  useEffect(() => {
    initializeInvoiceScreen();
    fetchInvoice();
  }, []);

  const createInvoice = () => {
    history.push(APP_URLS.INVOICES.ADD_INVOICES);
  };

  const editInvoice = ({ invoice_number }) => {
    const path = generatePath(APP_URLS.INVOICES.EDIT_INVOICES, {
      id: invoice_number,
    });
    history.push(path);
  };

  const readInvoice = ({ invoice_number }) => {
    const path = generatePath(APP_URLS.INVOICES.VIEW_INVOICES, {
      id: invoice_number,
    });
    history.push(path);
  };

  const handleExport = () => {
    if (fileType === 'PDF') {
      downloadInvoice(selectedRow.invoice_number);
    } else {
      if (toast.current) {
        toast.current.show({
          severity: 'info',
          summary: 'Info',
          detail: 'This feature is in progress. Please select PDF for now.',
          life: 3000,
        });
      }
    }
    closeModal();
  };

  const downloadInvoiceModelOpen = (rowData) => {
    setSelectedRow(rowData)
    setIsModalVisible(true)
  }

  const loadLazyData = (props) => {
    fetchInvoice(props);
  };

  const getToastProps = () => {
    if (isDownloadSuccess) {
      const toastTitle ="Invoice Download Successfully"
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }
    if (isDownloadError) {
      let toastTitle = "Error while Downloading Invoice";
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const invoicesProps = {
    createInvoice,
    editInvoice,
    readInvoice,
    downloadInvoiceModelOpen,
    screenPermissions,
    fetchInvoices: loadLazyData,
    ...invoicesData,
    tableData: {
      ...invoicesData.tableData,
      rows: invoiceListView,
    },
    paginationInfo: {
      ...invoicesData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.total_elements,
    },
    isLoading,
  }

  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");
  
  return (
    <div>
      <EzToast {...getToastProps()} />
      <Toast ref={toast} />
      <Invoices {...invoicesProps}/>
      <DownloadModal 
        visible={isModalVisible} 
        onExport={handleExport} 
        onHide={closeModal} 
        fileType={fileType} 
        setFileType={setFileType} 
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
      initializeInvoiceScreen: () => dispatch(initializeInvoiceScreen()),
      fetchInvoice: (payload) => dispatch(fetchInvoice(payload)),
      downloadInvoice: (payload) => dispatch(downloadInvoice(payload)),
    };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    invoiceListView: selectInvoiceList(state.invoiceReducer),
    showPageLevelLoader: selectIsLoadingPage(state.invoiceReducer),
    paginationInfo: selectPaginationInfo(state.invoiceReducer),
    isPageLevelError: selectIsPageLevelError(state.invoiceReducer),
    isFetchingGridData: selectIsLoading(state.invoiceReducer),
    isDownloadSuccess: selectFromInvoicesReducer(state,"isDownloadSuccess"),
    isDownloadError: selectFromInvoicesReducer(state,"isDownloadError"),
  };
};

const selectInvoiceList = (invoiceReducer) => invoiceReducer.invoiceListView;
const selectIsLoadingPage = (invoiceReducer) => invoiceReducer.isLoadingPage;
const selectPaginationInfo = (invoiceReducer) => invoiceReducer.paginationInfo;
const selectIsPageLevelError = (invoiceReducer) => invoiceReducer.isPageLevelError;
const selectIsLoading = (invoiceReducer) => invoiceReducer.isLoading;

const selectFromInvoicesReducer = (state, path) => {
  return selectInvoicesReducer(state)[path];
};

const selectInvoicesReducer = ({ invoiceReducer }) => {
  return invoiceReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesScreen);
